//create CRUD class with axios
import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import DataService from "./dataservice.base";

type ReportParams = {
  type: String;
  format: String;
  startDate: String;
  endDate?: String;
  storeId: String;
  storeName?: String;
  timestamp: string;
};

export default class ReportsDataService extends DataService {
  URL = "https://seal-app-xnngl.ondigitalocean.app/reports";
  // URL = "http://localhost:8080/reports";

  constructor(path: string) {
    super(path);
  }

  async getReport(params: ReportParams): Promise<AxiosResponse> {
    return await axios.post(this.URL, params);
  }

  async changePassword(params:any){
    return await axios.post(this.URL, params);
  }

  async downloadReport(params: ReportParams): Promise<any> {
    try {
      const response = await axios.post(this.URL, params, {
        responseType: "arraybuffer",
      });

      if (response) {
        const filename = response.headers
          ? response.headers?.get("Content-Disposition")?.split("=")[1]
          : undefined;

        fileDownload(response.data, filename);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async downloadPdfReport(params: ReportParams): Promise<any> {
    try {
      const response = await axios.post(this.URL, params, {
        responseType: "arraybuffer",
      });

      if (response) {
        const filename = getPdfFilename(response.headers?.get("Content-Disposition"));

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function getPdfFilename(input) {
  const regex = /filename="([^"]+)"/;
  const match = input.match(regex);
  return match ? match[1] : "download.pdf";
}