import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

//make random id
export const useMakeId = function () {
  return Math.random().toString(36).substr(2, 9);
};

export const useSumGrandTotal = function (items = []) {
  let s = items.reduce((a, b) => a + (b["amount"] || 0), 0);
  //decimal 2 digit
  return s.toFixed(2);
};

export const useDisplayDate = function (date, format) {
  return dayjs(date).format(format || "HH:mm:ss  DD/MMM");
};

//display moeny format and comma separated
export const useDisplayMoney = function (amount = 0) {
  return (
    amount ||
    (0)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

// display comma separated number
export const useDisplayNumber = function (amount = 0) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// generate running time HH : MM   from timestamp
export const useRunningTime = function (timestamp) {
  let now = new Date();
  let start = new Date(timestamp);
  let diff = now - start;
  let diffSeconds = diff / 1000;
  let hh = Math.floor(diffSeconds / 3600);
  let mm = Math.floor((diffSeconds - hh * 3600) / 60);
  let ss = Math.floor(diffSeconds - hh * 3600 - mm * 60);
  return `${hh}h ${mm}m`;
};

export const useRuningTimeDateFormat = function (timestamp) {
  // Extend Day.js with plugins
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  const now = dayjs();
  const time = dayjs(timestamp);
  const diff = now.diff(time);
  const timeDuration = dayjs.duration(diff);

  // Format the output as needed
  const days = timeDuration.days();
  const hours = timeDuration.hours();
  const minutes = timeDuration.minutes();

  // Format the output string
  let result = "";
  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  if (minutes > 0) result += `${minutes}m`;
  return result.trim();
};

// Format number e.g. add comma, set digit.
export const useFormatNumber = (
  value: number,
  digit?: number,
  fixed?: boolean,
  options?: { hideNaN?: string }
): string | number => {
  
  if (isNaN(value) && options?.hideNaN) {
    return options?.hideNaN;
  }
  if (isNaN(value)) {
    return "NaN";
  }

  return value.toLocaleString("en-EN", {
    minimumFractionDigits: fixed ? digit : 0,
    maximumFractionDigits: digit,
  });
};

export const useDigitFormat = (value: number, digits: number = 2) => {
  const number = Number(value);

  if (isNaN(number)) {
    return 0; // Handle cases where the input is not a number
  }

  if (number === 0) {
    return 0; // Return 0.00 if the input is 0
  }
  return parseFloat(number.toFixed(digits));
};
