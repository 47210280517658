import { JobAction, JobCard } from "~~/interface/Job";
import _ from "lodash";


export default class JobBuilder {

    private job: JobCard;

    constructor(table) {

        this.job = {
            table: table,
            zone : '',
            // setting: {
            //     sc : [0,0,0],
            //     dc1 : [0,0,0],
            //     dc2 : [0,0,0],
            //     cc : [0,0,0],
            //     tax : [0,0,0],
            // },
            items: [],
            note: "",
            action: JobAction.ADD,

            guests: -1,            
            employee: {
                employeeId: "",
                name: "",
            },
            print: true,
            // orderType: "dine_in",
            // priceMode : "default",
            jobId: new Date().valueOf().toString(),
            timestamp: new Date().toISOString(),
            // receiptUuid : "",  // เลข unique มี1เดียว
            // receiptId : "", //เลขรัน

        }

    }

    withTable(table) {
        this.job.table = table;
        return this;
    }
    withZone(zone) {
        this.job.zone = zone;
        return this;
    }
    withTargetTable(targetTable) {
        this.job.targetTable = targetTable;
        return this;
    }

    withAction(action) {
        this.job.action = action;
        return this;
    }

    withReceiptUuid(receiptUuid) {
        this.job.receiptUuid = receiptUuid;
        return this;
    }

    withReceiptId(receiptId) {
        this.job.receiptId = receiptId;
        return this;
    }

    withItems(items) {
        this.job.items = items;
        return this;
    }
    withItemsFromCart(items) {
        let items_cleaned = items.map((item) => {
            let new_item = {
                name: item.name,
                price: item.price,
                qty: item.qty,
                productId : item.productId,
                uuid : item.uuid,
                comments : item.comments || '',
            //    dc1 : item.dc1  
            }

            if (item.options) {
                new_item.options = item.options || [];
                new_item.options2 = item.options2 || [];


            }
            return new_item;
        });
        this.job.items = items_cleaned;
        return this;
    }

    withNote(note) {
        this.job.note = note;
        return this;
    }

    withPayment(payment) {
        this.job.payment = payment;
        return this;
    }

    withValue(value) {

        this.job.value = value;
        return this;

    }

    withGuests(guests = 2) {
        this.job.guests = guests;
        return this;
    }

    withEmployee(employee) {

        this.job.employee = {
            name: employee.name,
            employeeId: employee.employeeId,
        } //_.sample(tmp)
        return this;
    }

    withPrint(bool) {
        this.job.print = bool;
        return this;
    }


    withOrderType(orderType) {
        this.job.orderType = orderType;
        return this;
    }

    withJobId(jobId) {
        this.job.jobId = jobId;
        return this;
    }

    withCustom(payload) {

        this.job = {
            ...this.job,
            ...payload
        }
        return this;

    }

    withJobIdAndTimestamp (timestampISOString) {
        this.job.jobId = timestampISOString
        this.job.timestamp = timestampISOString
        return this;
    }

    build() {

        let ts = useServerTime().getCorrectedDayjsInstance().toISOString()
        this.job.jobId = ts
        this.job.timestamp = ts
        this.job.storeId = useStoreId()

        let job = _.cloneDeep(this.job)
        job =    removeEmptyStringAndUndefinedProperties(job);
        return job;
        
    }

}
 