

//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';
import DataService from './dataservice.base';


export default class ReceiptDataService extends DataService {


    constructor(path :string) {
        super(path);
        
    }


     // GET a single record by ID
    async getByDate(startDate : string, tags:Array<Object>): Promise<AxiosResponse> {
        return await this.axios.get(`${this.apiUrl}${this.path}/${startDate}?storeId=${this.storeId}`);
    }

     // GET a single record by ID
     async getByRange(startDate : string, endDate : string ): Promise<AxiosResponse> {
        return await this.axios.get(`${this.apiUrl}${this.path}/${startDate}/${endDate}}?storeId=${this.storeId}`);
    }


    async getReportSummary(startDate : string, endDate : string, tags:Array<Object> ): Promise<AxiosResponse> {      
        let payload = {
            startDate: startDate,
            endDate: endDate,
            storeId: this.storeId,
            tags:tags   
        }
        
        return await this.axios.post(`${this.apiUrl}${this.path}/reports/summary`, payload);
    }

    async changePayment(receipt:object){    
        const url = `${this.apiUrl}${this.path}/change-payments`
        return await this.axios.put(url, receipt);
    }

    async updateCustomGuest(receipt:object){
        //**** Please check url */
        const url = `${this.apiUrl}${this.path}/?storeId=${this.storeId}`
        return await this.axios.post(url, receipt);
    }
}