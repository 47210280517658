import _ from 'lodash'
import { SETTING_ID } from '~~/interface/Setting';


export const utilsItemGenKey = function genKey(item, ) {
  if (item?.options2  && item?.options2?.length > 0) {
    let options = item?.options2?.map((i) => {
      return `${i.productId}`
    })
    return `${item.productId}:${item.comments || ""}:${item.price}:${item.dc1?.toString()}:${options.join(':')}`
  }
  return `${item.productId}:${item.comments || ""}:${item.price}:${item.dc1?.toString()}`
}

export const utilsItemGenKeyInCart = function genKey(item, options2 = null) {
  if (options2) {
    let options = options2?.map((i) => {
      return `${i.productId}`
    })
    return `${item.productId}:${item.comments}:${item.price}:${options.join(':')}`
  }
  return `${item.productId}:${item.comments}:${item.price}`
}

export const utilsItemComputeOneTable = function conputeOneTable(tableInstance) {
   
  const roundingType = tableInstance.roundingType || SETTING_ID.ROUNDING_OPTION_NEAREST
  const { subTotal, dc1, sc, dc2, tax, cc, rounding, grandTotal } = utilsItemComputeSummary(tableInstance.items, roundingType)
  
  const g = Math.round((rounding+grandTotal) * 100) / 100
  return {
    subTotal: subTotal,
    dc1: dc1,
    sc: sc,
    dc2: dc2,
    cc:cc,
    tax: tax,    
    rounding: rounding,
    grandTotal: g
  }
}

export const utilsItemComputeSummary = function (items:Array<any>, roundingType:string) {
  //find rounding

  let beforeRounding = [
    __sumChain(items, 'extendedPrice'),
    __sumChain(items, 'dc1'),
    __sumChain(items, 'sc'),
    __sumChain(items, 'dc2'),
    __sumChain(items, 'cc'),
   
  ]

  let tax =  __sumChain(items, 'tax')
  if(tax > 0) { 
    beforeRounding.push(tax)
  }


  // place to 2 decimal
  beforeRounding = beforeRounding.map((item) => {
    return Math.round(item * 100) / 100
  })


  let sumBeforeRounding = beforeRounding.reduce((a, b) => a + b, 0)  
  let rounding = __rounding(sumBeforeRounding, roundingType)

  // rounding place to 2 decimal
  rounding = Math.round(rounding * 100) / 100

  return {
    subTotal: __sumChain(items, 'extendedPrice'),
    dc1: __sumChain(items, 'dc1'),
    sc: __sumChain(items, 'sc'),
    dc2: __sumChain(items, 'dc2'),
    tax: __sumChain(items, 'tax'),
    cc: __sumChain(items, 'cc'),
    rounding,
    grandTotal: sumBeforeRounding , // __sumChain(items, 'amount'), // ไปรวมข้างนแก

  }
}


export const removeEmptyStringAndUndefinedProperties =  function  (obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the property is an object or array (but not null), process it recursively
        removeEmptyStringAndUndefinedProperties(obj[key]);
    } else if (obj[key] === "" || obj[key] === undefined) {
        // If the property is an empty string or undefined, remove it
        delete obj[key];
    }
}
return obj;
}


export const __sumChain = function (items, key) {
  let l =  items.map((item) => {
    //if array
    let value = 0
    if (Array.isArray(item[key])) {
      value = item[key][2]
    } else {
      value = item[key]
    }
    return value
  })
  
  return l.reduce((a, b) => a + b, 0)
}

//create function rounding  money  (sumBeforeRounding)
function __rounding(total:number, method=SETTING_ID.ROUNDING_OPTION_NEAREST, increment = 1) {
  if (increment <= 0) increment = 1; 

  const ROUNDING_FOMULA = {
    [SETTING_ID.ROUNDING_OPTION_UP]: (Math.ceil(total / increment) * increment)-total,
    [SETTING_ID.ROUNDING_OPTION_DOWN]: -(total-Math.floor(total / increment) * increment),
    [SETTING_ID.ROUNDING_OPTION_NEAREST]: (Math.round(total / increment) * increment) - total,
    [SETTING_ID.ROUNDING_OPTION_NONE]: 0,
  } 

  if( ROUNDING_FOMULA[method] === 0) { 
    return 0
  }


  //Default By Nearest
  return ROUNDING_FOMULA[method] || ROUNDING_FOMULA[SETTING_ID.ROUNDING_OPTION_NEAREST]
}
