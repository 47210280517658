import { child, set } from "firebase/database";
import { doc, setDoc } from "firebase/firestore";
import { PiniaPluginContext } from "pinia";
import { JobAction } from "@/interface/job";
import dayjs from "dayjs"

function MyPiniaPlugin({ store }: PiniaPluginContext) {
  if (store.$id == "jobs") {
    
     window.JOBSCHAIN = store
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin);
});
