import { default as _404xgBDKNbkYSMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/404.vue?macro=true";
import { default as expiredJJN6OUwuz6Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/expired.vue?macro=true";
import { default as forgot_45password68tBNczx8yMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/forgot-password.vue?macro=true";
import { default as indexl9G0YRdboEMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/index.vue?macro=true";
import { default as loginQx6cuzqbTjMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/login.vue?macro=true";
import { default as notallowedG1jCcYJUyBMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/notallowed.vue?macro=true";
import { default as runmodegBdkhm5RN3Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/runmode.vue?macro=true";
import { default as signup38y4CPniMnMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/signup.vue?macro=true";
import { default as unauthorizedTj4XkKYTI9Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/unauthorized.vue?macro=true";
import { default as indexdn1BHJsnnrMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/index.vue?macro=true";
import { default as receiptsoE5VXXadILMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/receipts.vue?macro=true";
import { default as live3dBUsqf827Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/live.vue?macro=true";
import { default as productsn0y1zLbUw7Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/products.vue?macro=true";
import { default as voided_45items0eNDYbBlAUMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/voided-items.vue?macro=true";
import { default as closeKwGteUzlslMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/close.vue?macro=true";
import { default as paymentsZgSxZHgMA5Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/payments.vue?macro=true";
import { default as dashboardXkVdY5MIzCMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard.vue?macro=true";
import { default as logs1zAoQqOG1tMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/history/pages/logs.vue?macro=true";
import { default as listpeeDFmZ9bkMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/list.vue?macro=true";
import { default as insertuHbixQAfNgMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/insert.vue?macro=true";
import { default as addf2FcjTerPEMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/add.vue?macro=true";
import { default as editwQDAzsHIqzMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/edit.vue?macro=true";
import { default as detailBdKWL6ukZ7Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/detail.vue?macro=true";
import { default as reportbiL2wI7q44Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/report.vue?macro=true";
import { default as inventoryYDoDMS2sEmMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory.vue?macro=true";
import { default as indexryfKhjUFssMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/pages/index.vue?macro=true";
import { default as cart5pvaFAwVA7Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/pages/cart.vue?macro=true";
import { default as indexnixOnNc5xKMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/index.vue?macro=true";
import { default as groupsTSA83uxRGWMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/groups.vue?macro=true";
import { default as itemsngTOLJNtFQMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items.vue?macro=true";
import { default as addonWhZGhYC4v8Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/addon.vue?macro=true";
import { default as qrcode6p8ptLKEJ5Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/qrcode.vue?macro=true";
import { default as tag3TWTC20Jp0Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/tag.vue?macro=true";
import { default as categoriesl2gSVbwhz8Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/categories.vue?macro=true";
import { default as channelw2W7VrnrPiMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/channel.vue?macro=true";
import { default as printerZcW5mljcGAMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/printer.vue?macro=true";
import { default as groups_batch20eC4sjsgHMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/groups_batch.vue?macro=true";
import { default as items_batchEPCEF1QjlHMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items_batch.vue?macro=true";
import { default as items_batch2xgGseLzu3xMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items_batch2.vue?macro=true";
import { default as openMenuwqAegT3IxfMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/openMenu.vue?macro=true";
import { default as menu5zvjq5XFsNMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu.vue?macro=true";
import { default as indexCXWWzZfTwPMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/index.vue?macro=true";
import { default as summary_45dailykZfBR9weBSMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/summary-daily.vue?macro=true";
import { default as receiptsyQDiQNhZFHMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/receipts.vue?macro=true";
import { default as receipts_45by_45dateuMjN15xonGMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/receipts-by-date.vue?macro=true";
import { default as voided_45billsg2Pv05mJBLMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/voided-bills.vue?macro=true";
import { default as productsQ7bQtJjMXsMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products.vue?macro=true";
import { default as products_45dailySz5ZbxATDKMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-daily.vue?macro=true";
import { default as transaction_45by_45hourhQ8V6oGuxUMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/transaction-by-hour.vue?macro=true";
import { default as products_45voidedsa5DWXf6KOMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-voided.vue?macro=true";
import { default as change_45products_45priceopjNfE2q1yMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/change-products-price.vue?macro=true";
import { default as products_45open_45menuJ2F8LIUG5OMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-open-menu.vue?macro=true";
import { default as activity_45log4Qfq5Q1Qm3Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/activity-log.vue?macro=true";
import { default as sales_45by_45employeeCJseUooNG7Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/sales-by-employee.vue?macro=true";
import { default as index88jtQwP4biMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/index.vue?macro=true";
import { default as tablesv6gipkNNJdMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/tables.vue?macro=true";
import { default as channelsbTIXEmV5qSMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/channels.vue?macro=true";
import { default as employeesf7hTuprJ7vMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/employees.vue?macro=true";
import { default as paymentslgaCD1Ln93Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/payments.vue?macro=true";
import { default as printersXScr55J0QEMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/printers.vue?macro=true";
import { default as mobile_45connectm4XPqkFIC2Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/mobile-connect.vue?macro=true";
import { default as invite_45userfG0CeoOTurMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/invite-user.vue?macro=true";
import { default as receipt_45tagsA4xwha9gDMMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/receipt-tags.vue?macro=true";
import { default as indexjq31SldoS5Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/index.vue?macro=true";
import { default as index5RMw118FxiMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/index.vue?macro=true";
import { default as promptpayuWzJA1cOk4Meta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/promptpay.vue?macro=true";
import { default as taxInvoiceInfoTd94qhacRSMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/taxInvoiceInfo.vue?macro=true";
import { default as topupQy9aPZSNxdMeta } from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup.vue?macro=true";
export default [
  {
    name: _404xgBDKNbkYSMeta?.name ?? "404",
    path: _404xgBDKNbkYSMeta?.path ?? "/404",
    meta: _404xgBDKNbkYSMeta || {},
    alias: _404xgBDKNbkYSMeta?.alias || [],
    redirect: _404xgBDKNbkYSMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/404.vue").then(m => m.default || m)
  },
  {
    name: expiredJJN6OUwuz6Meta?.name ?? "expired",
    path: expiredJJN6OUwuz6Meta?.path ?? "/expired",
    meta: expiredJJN6OUwuz6Meta || {},
    alias: expiredJJN6OUwuz6Meta?.alias || [],
    redirect: expiredJJN6OUwuz6Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/expired.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password68tBNczx8yMeta?.name ?? "forgot-password",
    path: forgot_45password68tBNczx8yMeta?.path ?? "/forgot-password",
    meta: forgot_45password68tBNczx8yMeta || {},
    alias: forgot_45password68tBNczx8yMeta?.alias || [],
    redirect: forgot_45password68tBNczx8yMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexl9G0YRdboEMeta?.name ?? "index",
    path: indexl9G0YRdboEMeta?.path ?? "/",
    meta: indexl9G0YRdboEMeta || {},
    alias: indexl9G0YRdboEMeta?.alias || [],
    redirect: indexl9G0YRdboEMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginQx6cuzqbTjMeta?.name ?? "login",
    path: loginQx6cuzqbTjMeta?.path ?? "/login",
    meta: loginQx6cuzqbTjMeta || {},
    alias: loginQx6cuzqbTjMeta?.alias || [],
    redirect: loginQx6cuzqbTjMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notallowedG1jCcYJUyBMeta?.name ?? "notallowed",
    path: notallowedG1jCcYJUyBMeta?.path ?? "/notallowed",
    meta: notallowedG1jCcYJUyBMeta || {},
    alias: notallowedG1jCcYJUyBMeta?.alias || [],
    redirect: notallowedG1jCcYJUyBMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/notallowed.vue").then(m => m.default || m)
  },
  {
    name: runmodegBdkhm5RN3Meta?.name ?? "runmode",
    path: runmodegBdkhm5RN3Meta?.path ?? "/runmode",
    meta: runmodegBdkhm5RN3Meta || {},
    alias: runmodegBdkhm5RN3Meta?.alias || [],
    redirect: runmodegBdkhm5RN3Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/runmode.vue").then(m => m.default || m)
  },
  {
    name: signup38y4CPniMnMeta?.name ?? "signup",
    path: signup38y4CPniMnMeta?.path ?? "/signup",
    meta: signup38y4CPniMnMeta || {},
    alias: signup38y4CPniMnMeta?.alias || [],
    redirect: signup38y4CPniMnMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedTj4XkKYTI9Meta?.name ?? "unauthorized",
    path: unauthorizedTj4XkKYTI9Meta?.path ?? "/unauthorized",
    meta: unauthorizedTj4XkKYTI9Meta || {},
    alias: unauthorizedTj4XkKYTI9Meta?.alias || [],
    redirect: unauthorizedTj4XkKYTI9Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: dashboardXkVdY5MIzCMeta?.name ?? "dashboard",
    path: dashboardXkVdY5MIzCMeta?.path ?? "/dashboard",
    meta: dashboardXkVdY5MIzCMeta || {},
    alias: dashboardXkVdY5MIzCMeta?.alias || [],
    redirect: dashboardXkVdY5MIzCMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: indexdn1BHJsnnrMeta?.name ?? "",
    path: indexdn1BHJsnnrMeta?.path ?? "",
    meta: indexdn1BHJsnnrMeta || {},
    alias: indexdn1BHJsnnrMeta?.alias || [],
    redirect: indexdn1BHJsnnrMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: receiptsoE5VXXadILMeta?.name ?? "receipts",
    path: receiptsoE5VXXadILMeta?.path ?? "receipts",
    meta: receiptsoE5VXXadILMeta || {},
    alias: receiptsoE5VXXadILMeta?.alias || [],
    redirect: receiptsoE5VXXadILMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/receipts.vue").then(m => m.default || m)
  },
  {
    name: live3dBUsqf827Meta?.name ?? "live",
    path: live3dBUsqf827Meta?.path ?? "live",
    meta: live3dBUsqf827Meta || {},
    alias: live3dBUsqf827Meta?.alias || [],
    redirect: live3dBUsqf827Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/live.vue").then(m => m.default || m)
  },
  {
    name: productsn0y1zLbUw7Meta?.name ?? "products",
    path: productsn0y1zLbUw7Meta?.path ?? "products",
    meta: productsn0y1zLbUw7Meta || {},
    alias: productsn0y1zLbUw7Meta?.alias || [],
    redirect: productsn0y1zLbUw7Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/products.vue").then(m => m.default || m)
  },
  {
    name: voided_45items0eNDYbBlAUMeta?.name ?? "voided-items",
    path: voided_45items0eNDYbBlAUMeta?.path ?? "voided-items",
    meta: voided_45items0eNDYbBlAUMeta || {},
    alias: voided_45items0eNDYbBlAUMeta?.alias || [],
    redirect: voided_45items0eNDYbBlAUMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/voided-items.vue").then(m => m.default || m)
  },
  {
    name: closeKwGteUzlslMeta?.name ?? "close",
    path: closeKwGteUzlslMeta?.path ?? "/dashboard/close",
    meta: closeKwGteUzlslMeta || {},
    alias: closeKwGteUzlslMeta?.alias || [],
    redirect: closeKwGteUzlslMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/close.vue").then(m => m.default || m)
  },
  {
    name: paymentsZgSxZHgMA5Meta?.name ?? "dashboard-payments",
    path: paymentsZgSxZHgMA5Meta?.path ?? "/dashboard/payments",
    meta: paymentsZgSxZHgMA5Meta || {},
    alias: paymentsZgSxZHgMA5Meta?.alias || [],
    redirect: paymentsZgSxZHgMA5Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/dashboard/pages/dashboard/payments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: logs1zAoQqOG1tMeta?.name ?? "history",
    path: logs1zAoQqOG1tMeta?.path ?? "/logs",
    meta: logs1zAoQqOG1tMeta || {},
    alias: logs1zAoQqOG1tMeta?.alias || [],
    redirect: logs1zAoQqOG1tMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/history/pages/logs.vue").then(m => m.default || m)
  },
  {
    name: inventoryYDoDMS2sEmMeta?.name ?? "inventory",
    path: inventoryYDoDMS2sEmMeta?.path ?? "/inventory",
    meta: inventoryYDoDMS2sEmMeta || {},
    alias: inventoryYDoDMS2sEmMeta?.alias || [],
    redirect: inventoryYDoDMS2sEmMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory.vue").then(m => m.default || m),
    children: [
  {
    name: listpeeDFmZ9bkMeta?.name ?? "list",
    path: listpeeDFmZ9bkMeta?.path ?? "list",
    meta: listpeeDFmZ9bkMeta || {},
    alias: listpeeDFmZ9bkMeta?.alias || [],
    redirect: listpeeDFmZ9bkMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/list.vue").then(m => m.default || m)
  },
  {
    name: insertuHbixQAfNgMeta?.name ?? "insert",
    path: insertuHbixQAfNgMeta?.path ?? "insert",
    meta: insertuHbixQAfNgMeta || {},
    alias: insertuHbixQAfNgMeta?.alias || [],
    redirect: insertuHbixQAfNgMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/insert.vue").then(m => m.default || m)
  },
  {
    name: addf2FcjTerPEMeta?.name ?? "add",
    path: addf2FcjTerPEMeta?.path ?? "add",
    meta: addf2FcjTerPEMeta || {},
    alias: addf2FcjTerPEMeta?.alias || [],
    redirect: addf2FcjTerPEMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/add.vue").then(m => m.default || m)
  },
  {
    name: editwQDAzsHIqzMeta?.name ?? "edit",
    path: editwQDAzsHIqzMeta?.path ?? "edit",
    meta: editwQDAzsHIqzMeta || {},
    alias: editwQDAzsHIqzMeta?.alias || [],
    redirect: editwQDAzsHIqzMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/edit.vue").then(m => m.default || m)
  },
  {
    name: detailBdKWL6ukZ7Meta?.name ?? "detail",
    path: detailBdKWL6ukZ7Meta?.path ?? "detail",
    meta: detailBdKWL6ukZ7Meta || {},
    alias: detailBdKWL6ukZ7Meta?.alias || [],
    redirect: detailBdKWL6ukZ7Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/detail.vue").then(m => m.default || m)
  },
  {
    name: reportbiL2wI7q44Meta?.name ?? "inventory-report",
    path: reportbiL2wI7q44Meta?.path ?? "report",
    meta: reportbiL2wI7q44Meta || {},
    alias: reportbiL2wI7q44Meta?.alias || [],
    redirect: reportbiL2wI7q44Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/inventory/pages/inventory/report.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexryfKhjUFssMeta?.name ?? "pos",
    path: indexryfKhjUFssMeta?.path ?? "/pos",
    meta: indexryfKhjUFssMeta || {},
    alias: indexryfKhjUFssMeta?.alias || [],
    redirect: indexryfKhjUFssMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/pages/index.vue").then(m => m.default || m)
  },
  {
    name: cart5pvaFAwVA7Meta?.name ?? "cart",
    path: cart5pvaFAwVA7Meta?.path ?? "/pos/cart",
    meta: cart5pvaFAwVA7Meta || {},
    alias: cart5pvaFAwVA7Meta?.alias || [],
    redirect: cart5pvaFAwVA7Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: menu5zvjq5XFsNMeta?.name ?? "menu",
    path: menu5zvjq5XFsNMeta?.path ?? "/menu",
    meta: menu5zvjq5XFsNMeta || {},
    alias: menu5zvjq5XFsNMeta?.alias || [],
    redirect: menu5zvjq5XFsNMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu.vue").then(m => m.default || m),
    children: [
  {
    name: indexnixOnNc5xKMeta?.name ?? "",
    path: indexnixOnNc5xKMeta?.path ?? "",
    meta: indexnixOnNc5xKMeta || {},
    alias: indexnixOnNc5xKMeta?.alias || [],
    redirect: indexnixOnNc5xKMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: groupsTSA83uxRGWMeta?.name ?? "groups",
    path: groupsTSA83uxRGWMeta?.path ?? "groups",
    meta: groupsTSA83uxRGWMeta || {},
    alias: groupsTSA83uxRGWMeta?.alias || [],
    redirect: groupsTSA83uxRGWMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/groups.vue").then(m => m.default || m)
  },
  {
    name: itemsngTOLJNtFQMeta?.name ?? "items",
    path: itemsngTOLJNtFQMeta?.path ?? "items",
    meta: itemsngTOLJNtFQMeta || {},
    alias: itemsngTOLJNtFQMeta?.alias || [],
    redirect: itemsngTOLJNtFQMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items.vue").then(m => m.default || m)
  },
  {
    name: addonWhZGhYC4v8Meta?.name ?? "addon",
    path: addonWhZGhYC4v8Meta?.path ?? "addon",
    meta: addonWhZGhYC4v8Meta || {},
    alias: addonWhZGhYC4v8Meta?.alias || [],
    redirect: addonWhZGhYC4v8Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/addon.vue").then(m => m.default || m)
  },
  {
    name: qrcode6p8ptLKEJ5Meta?.name ?? "qrcode",
    path: qrcode6p8ptLKEJ5Meta?.path ?? "qrcode",
    meta: qrcode6p8ptLKEJ5Meta || {},
    alias: qrcode6p8ptLKEJ5Meta?.alias || [],
    redirect: qrcode6p8ptLKEJ5Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/qrcode.vue").then(m => m.default || m)
  },
  {
    name: tag3TWTC20Jp0Meta?.name ?? "tag",
    path: tag3TWTC20Jp0Meta?.path ?? "tag",
    meta: tag3TWTC20Jp0Meta || {},
    alias: tag3TWTC20Jp0Meta?.alias || [],
    redirect: tag3TWTC20Jp0Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/tag.vue").then(m => m.default || m)
  },
  {
    name: categoriesl2gSVbwhz8Meta?.name ?? "category",
    path: categoriesl2gSVbwhz8Meta?.path ?? "categories",
    meta: categoriesl2gSVbwhz8Meta || {},
    alias: categoriesl2gSVbwhz8Meta?.alias || [],
    redirect: categoriesl2gSVbwhz8Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/categories.vue").then(m => m.default || m)
  },
  {
    name: channelw2W7VrnrPiMeta?.name ?? "channel",
    path: channelw2W7VrnrPiMeta?.path ?? "channel",
    meta: channelw2W7VrnrPiMeta || {},
    alias: channelw2W7VrnrPiMeta?.alias || [],
    redirect: channelw2W7VrnrPiMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/channel.vue").then(m => m.default || m)
  },
  {
    name: printerZcW5mljcGAMeta?.name ?? "printer",
    path: printerZcW5mljcGAMeta?.path ?? "printer",
    meta: printerZcW5mljcGAMeta || {},
    alias: printerZcW5mljcGAMeta?.alias || [],
    redirect: printerZcW5mljcGAMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/printer.vue").then(m => m.default || m)
  },
  {
    name: groups_batch20eC4sjsgHMeta?.name ?? "groups_batch",
    path: groups_batch20eC4sjsgHMeta?.path ?? "groups_batch",
    meta: groups_batch20eC4sjsgHMeta || {},
    alias: groups_batch20eC4sjsgHMeta?.alias || [],
    redirect: groups_batch20eC4sjsgHMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/groups_batch.vue").then(m => m.default || m)
  },
  {
    name: items_batchEPCEF1QjlHMeta?.name ?? "items_batch",
    path: items_batchEPCEF1QjlHMeta?.path ?? "items_batch",
    meta: items_batchEPCEF1QjlHMeta || {},
    alias: items_batchEPCEF1QjlHMeta?.alias || [],
    redirect: items_batchEPCEF1QjlHMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items_batch.vue").then(m => m.default || m)
  },
  {
    name: items_batch2xgGseLzu3xMeta?.name ?? "items_batch2",
    path: items_batch2xgGseLzu3xMeta?.path ?? "items_batch2",
    meta: items_batch2xgGseLzu3xMeta || {},
    alias: items_batch2xgGseLzu3xMeta?.alias || [],
    redirect: items_batch2xgGseLzu3xMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/items_batch2.vue").then(m => m.default || m)
  },
  {
    name: openMenuwqAegT3IxfMeta?.name ?? "openMenu",
    path: openMenuwqAegT3IxfMeta?.path ?? "openMenu",
    meta: openMenuwqAegT3IxfMeta || {},
    alias: openMenuwqAegT3IxfMeta?.alias || [],
    redirect: openMenuwqAegT3IxfMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/product/pages/menu/openMenu.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCXWWzZfTwPMeta?.name ?? "reports",
    path: indexCXWWzZfTwPMeta?.path ?? "/reports",
    meta: indexCXWWzZfTwPMeta || {},
    alias: indexCXWWzZfTwPMeta?.alias || [],
    redirect: indexCXWWzZfTwPMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/index.vue").then(m => m.default || m)
  },
  {
    name: summary_45dailykZfBR9weBSMeta?.name ?? "summary-daily",
    path: summary_45dailykZfBR9weBSMeta?.path ?? "/reports/summary-daily",
    meta: summary_45dailykZfBR9weBSMeta || {},
    alias: summary_45dailykZfBR9weBSMeta?.alias || [],
    redirect: summary_45dailykZfBR9weBSMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/summary-daily.vue").then(m => m.default || m)
  },
  {
    name: receiptsyQDiQNhZFHMeta?.name ?? "report-receipts",
    path: receiptsyQDiQNhZFHMeta?.path ?? "/reports/receipts",
    meta: receiptsyQDiQNhZFHMeta || {},
    alias: receiptsyQDiQNhZFHMeta?.alias || [],
    redirect: receiptsyQDiQNhZFHMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/receipts.vue").then(m => m.default || m)
  },
  {
    name: receipts_45by_45dateuMjN15xonGMeta?.name ?? "report-receipts-by-date",
    path: receipts_45by_45dateuMjN15xonGMeta?.path ?? "/reports/receipts-by-date",
    meta: receipts_45by_45dateuMjN15xonGMeta || {},
    alias: receipts_45by_45dateuMjN15xonGMeta?.alias || [],
    redirect: receipts_45by_45dateuMjN15xonGMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/receipts-by-date.vue").then(m => m.default || m)
  },
  {
    name: voided_45billsg2Pv05mJBLMeta?.name ?? "report-voided-bills",
    path: voided_45billsg2Pv05mJBLMeta?.path ?? "/reports/voided-bills",
    meta: voided_45billsg2Pv05mJBLMeta || {},
    alias: voided_45billsg2Pv05mJBLMeta?.alias || [],
    redirect: voided_45billsg2Pv05mJBLMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/voided-bills.vue").then(m => m.default || m)
  },
  {
    name: productsQ7bQtJjMXsMeta?.name ?? "report-products",
    path: productsQ7bQtJjMXsMeta?.path ?? "/reports/products",
    meta: productsQ7bQtJjMXsMeta || {},
    alias: productsQ7bQtJjMXsMeta?.alias || [],
    redirect: productsQ7bQtJjMXsMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products.vue").then(m => m.default || m)
  },
  {
    name: products_45dailySz5ZbxATDKMeta?.name ?? "products-daily",
    path: products_45dailySz5ZbxATDKMeta?.path ?? "/reports/products-daily",
    meta: products_45dailySz5ZbxATDKMeta || {},
    alias: products_45dailySz5ZbxATDKMeta?.alias || [],
    redirect: products_45dailySz5ZbxATDKMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-daily.vue").then(m => m.default || m)
  },
  {
    name: transaction_45by_45hourhQ8V6oGuxUMeta?.name ?? "transaction-by-hour",
    path: transaction_45by_45hourhQ8V6oGuxUMeta?.path ?? "/reports/transaction-by-hour",
    meta: transaction_45by_45hourhQ8V6oGuxUMeta || {},
    alias: transaction_45by_45hourhQ8V6oGuxUMeta?.alias || [],
    redirect: transaction_45by_45hourhQ8V6oGuxUMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/transaction-by-hour.vue").then(m => m.default || m)
  },
  {
    name: products_45voidedsa5DWXf6KOMeta?.name ?? "products-voided",
    path: products_45voidedsa5DWXf6KOMeta?.path ?? "/reports/products-voided",
    meta: products_45voidedsa5DWXf6KOMeta || {},
    alias: products_45voidedsa5DWXf6KOMeta?.alias || [],
    redirect: products_45voidedsa5DWXf6KOMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-voided.vue").then(m => m.default || m)
  },
  {
    name: change_45products_45priceopjNfE2q1yMeta?.name ?? "change-products-price",
    path: change_45products_45priceopjNfE2q1yMeta?.path ?? "/reports/change-products-price",
    meta: change_45products_45priceopjNfE2q1yMeta || {},
    alias: change_45products_45priceopjNfE2q1yMeta?.alias || [],
    redirect: change_45products_45priceopjNfE2q1yMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/change-products-price.vue").then(m => m.default || m)
  },
  {
    name: products_45open_45menuJ2F8LIUG5OMeta?.name ?? "products-open-menu",
    path: products_45open_45menuJ2F8LIUG5OMeta?.path ?? "/reports/products-open-menu",
    meta: products_45open_45menuJ2F8LIUG5OMeta || {},
    alias: products_45open_45menuJ2F8LIUG5OMeta?.alias || [],
    redirect: products_45open_45menuJ2F8LIUG5OMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/products-open-menu.vue").then(m => m.default || m)
  },
  {
    name: activity_45log4Qfq5Q1Qm3Meta?.name ?? "activity-log",
    path: activity_45log4Qfq5Q1Qm3Meta?.path ?? "/reports/activity-log",
    meta: activity_45log4Qfq5Q1Qm3Meta || {},
    alias: activity_45log4Qfq5Q1Qm3Meta?.alias || [],
    redirect: activity_45log4Qfq5Q1Qm3Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/activity-log.vue").then(m => m.default || m)
  },
  {
    name: sales_45by_45employeeCJseUooNG7Meta?.name ?? "sales-by-employee",
    path: sales_45by_45employeeCJseUooNG7Meta?.path ?? "/reports/sales-by-employee",
    meta: sales_45by_45employeeCJseUooNG7Meta || {},
    alias: sales_45by_45employeeCJseUooNG7Meta?.alias || [],
    redirect: sales_45by_45employeeCJseUooNG7Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/reports/pages/sales-by-employee.vue").then(m => m.default || m)
  },
  {
    name: indexjq31SldoS5Meta?.name ?? "setting",
    path: indexjq31SldoS5Meta?.path ?? "/settings",
    meta: indexjq31SldoS5Meta || {},
    alias: indexjq31SldoS5Meta?.alias || [],
    redirect: indexjq31SldoS5Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: index88jtQwP4biMeta?.name ?? "",
    path: index88jtQwP4biMeta?.path ?? "",
    meta: index88jtQwP4biMeta || {},
    alias: index88jtQwP4biMeta?.alias || [],
    redirect: index88jtQwP4biMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: tablesv6gipkNNJdMeta?.name ?? "tables",
    path: tablesv6gipkNNJdMeta?.path ?? "tables",
    meta: tablesv6gipkNNJdMeta || {},
    alias: tablesv6gipkNNJdMeta?.alias || [],
    redirect: tablesv6gipkNNJdMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/tables.vue").then(m => m.default || m)
  },
  {
    name: channelsbTIXEmV5qSMeta?.name ?? "channels",
    path: channelsbTIXEmV5qSMeta?.path ?? "channels",
    meta: channelsbTIXEmV5qSMeta || {},
    alias: channelsbTIXEmV5qSMeta?.alias || [],
    redirect: channelsbTIXEmV5qSMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/channels.vue").then(m => m.default || m)
  },
  {
    name: employeesf7hTuprJ7vMeta?.name ?? "employees",
    path: employeesf7hTuprJ7vMeta?.path ?? "employees",
    meta: employeesf7hTuprJ7vMeta || {},
    alias: employeesf7hTuprJ7vMeta?.alias || [],
    redirect: employeesf7hTuprJ7vMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/employees.vue").then(m => m.default || m)
  },
  {
    name: paymentslgaCD1Ln93Meta?.name ?? "payments",
    path: paymentslgaCD1Ln93Meta?.path ?? "payments",
    meta: paymentslgaCD1Ln93Meta || {},
    alias: paymentslgaCD1Ln93Meta?.alias || [],
    redirect: paymentslgaCD1Ln93Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/payments.vue").then(m => m.default || m)
  },
  {
    name: printersXScr55J0QEMeta?.name ?? "printers",
    path: printersXScr55J0QEMeta?.path ?? "printers",
    meta: printersXScr55J0QEMeta || {},
    alias: printersXScr55J0QEMeta?.alias || [],
    redirect: printersXScr55J0QEMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/printers.vue").then(m => m.default || m)
  },
  {
    name: mobile_45connectm4XPqkFIC2Meta?.name ?? "mobile-connect",
    path: mobile_45connectm4XPqkFIC2Meta?.path ?? "mobile-connect",
    meta: mobile_45connectm4XPqkFIC2Meta || {},
    alias: mobile_45connectm4XPqkFIC2Meta?.alias || [],
    redirect: mobile_45connectm4XPqkFIC2Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/mobile-connect.vue").then(m => m.default || m)
  },
  {
    name: invite_45userfG0CeoOTurMeta?.name ?? "invite-user",
    path: invite_45userfG0CeoOTurMeta?.path ?? "invite-user",
    meta: invite_45userfG0CeoOTurMeta || {},
    alias: invite_45userfG0CeoOTurMeta?.alias || [],
    redirect: invite_45userfG0CeoOTurMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/invite-user.vue").then(m => m.default || m)
  },
  {
    name: receipt_45tagsA4xwha9gDMMeta?.name ?? "receipt-tags",
    path: receipt_45tagsA4xwha9gDMMeta?.path ?? "receipt-tags",
    meta: receipt_45tagsA4xwha9gDMMeta || {},
    alias: receipt_45tagsA4xwha9gDMMeta?.alias || [],
    redirect: receipt_45tagsA4xwha9gDMMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/setting/pages/settings/receipt-tags.vue").then(m => m.default || m)
  }
]
  },
  {
    name: topupQy9aPZSNxdMeta?.name ?? "topup",
    path: topupQy9aPZSNxdMeta?.path ?? "/topup",
    meta: topupQy9aPZSNxdMeta || {},
    alias: topupQy9aPZSNxdMeta?.alias || [],
    redirect: topupQy9aPZSNxdMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup.vue").then(m => m.default || m),
    children: [
  {
    name: index5RMw118FxiMeta?.name ?? "",
    path: index5RMw118FxiMeta?.path ?? "",
    meta: index5RMw118FxiMeta || {},
    alias: index5RMw118FxiMeta?.alias || [],
    redirect: index5RMw118FxiMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/index.vue").then(m => m.default || m)
  },
  {
    name: promptpayuWzJA1cOk4Meta?.name ?? "",
    path: promptpayuWzJA1cOk4Meta?.path ?? "promptpay",
    meta: promptpayuWzJA1cOk4Meta || {},
    alias: promptpayuWzJA1cOk4Meta?.alias || [],
    redirect: promptpayuWzJA1cOk4Meta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/promptpay.vue").then(m => m.default || m)
  },
  {
    name: taxInvoiceInfoTd94qhacRSMeta?.name ?? "",
    path: taxInvoiceInfoTd94qhacRSMeta?.path ?? "taxInvoiceInfo",
    meta: taxInvoiceInfoTd94qhacRSMeta || {},
    alias: taxInvoiceInfoTd94qhacRSMeta?.alias || [],
    redirect: taxInvoiceInfoTd94qhacRSMeta?.redirect,
    component: () => import("/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/topup/pages/topup/taxInvoiceInfo.vue").then(m => m.default || m)
  }
]
  }
]