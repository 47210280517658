//create CRUD class with axios
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import _, { forEach } from "lodash";
import { SETTING_ID } from "@/interface/Setting";
import { promptpay } from "@/modules/core/utils/canvas";

const PRINTER_NAME = "pos8080";

const STYLE_FONT_SIZE = {
  ITEMS: 10,
  GRAND_TOTAL: 14,
  GENERAL_TEXT: 10,
};

const REPLACE_ID = {
  PREVIEW_HEADER: "PREVIEW_HEADER",
  PREVIEW_FOOTER: "PREVIEW_FOOTER",

  RECEIPT_HEADER: "RECEIPT_HEADER",
  RECEIPT_FOOTER: "RECEIPT_FOOTER",
};

export default class ThermalPrinterReceipt extends ThermalPrinterBase {
  async setPrintPreview(payload: any) {
    const settingsStore = useSettingsStore();
    let content = [];

    //draw detail header
    content.push(
      {
        type: "text",
        value: `*** ใบตรวจสอบราคาสินค้า ***`,
        style: {
          fontFamily: "Arial",
          fontSize: STYLE_FONT_SIZE.GRAND_TOTAL,
          fontStyle: "normal",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },
      {
        replaceId: REPLACE_ID.PREVIEW_HEADER,
        type: "text",
        value: settingsStore.getValueBysettingId(
          SETTING_ID.PRINTER_PREVIEW_HEADER,
          null
        ),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },

      {
        type: "text",
        value: `Date : ${dayjs(payload.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      {
        type: "text",
        value: `Cashier : ${payload.employee.name}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      {
        type: "text",
        value: `Guests : ${payload.metadata.guests}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      { type: "newline", value: "10" },

      {
        type: "text",
        value:
          `${payload.table}` +
          `${payload.bill == "all" ? "" : "-" + payload.bill}`,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "16",
      },
      { type: "line" }
    );

    const isSkipPriceZero = settingsStore.getValueBysettingId(
      SETTING_ID.PRINTER_JOB_SKIP_PRICE_ZERO,
      false
    );

    // draw items
    payload.items.forEach((item) => {
      //Skip Price Zero
      if (
        isSkipPriceZero === true &&
        item.price == 0 &&
        item.addOnPrice === 0
      ) {
        //add on price is topping total amount
        return;
      }

      content.push({
        type: "text2cols",
        value: `${item.qty} ${item.name}`,
        value2: `${displayCommaMoney(item.extendedPrice, true)}`,
        style: {
          fontFamily: "Arial",
          fontSize: this.customFontSize(10),
          fontStyle: "normal",
        },
      });

      if (item.options2 && item.options2.length > 0) {
        item.options2.forEach((option) => {
          content.push({
            type: "text2cols",
            value: `     - ${option.name}  x${option.qty}`,
            value2: ` `,
            style: {
              fontFamily: "Arial",
              fontSize: this.customFontSize(10),
              fontStyle: "normal",
            },
          });
        });
      }
      const isShowComment = settingsStore.getValueBysettingId(
        SETTING_ID.PRINTER_RECEIPT_COMMENTS,
        false
      );

      if (item.comments && isShowComment) {
        content.push({
          type: "text2cols",
          value: `     # ${item.comments}`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }

      if (item.dc1[2] != 0 && item.dc1[0] == 0) {
        content.push({
          type: "text2cols",
          value: `     - ส่วนลด ${item.dc1[1]}%`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }

      if (item.dc1[2] != 0 && item.dc1[0] == 1) {
        content.push({
          type: "text2cols",
          value: `     - ส่วนลด ${item.dc1[1]}`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }
    });

    // draw total summary
    this._drawSummary(content, payload);

    // draw Qrcode
    // content.push(
    //     {
    //         type: 'text',
    //         value: `Prompt Pay`,
    //         style: {
    //             fontFamily: 'Arial',
    //             fontSize: 14,
    //             fontStyle: 'bold',
    //             align: 'center'
    //         }
    //     },
    //     {
    //         type: 'qrcode',
    //         value: 'https://www.instagram.com/p.thanyakorn/',
    //         style: {
    //             fontSize: 50,
    //             align: 'center'
    //         }
    //     },
    //     {
    //         type: 'newline',
    //         value: '10'
    //     }
    // )

    // draw footer thanks
    debugger;
    if (payload.payment?.note) {
      content.push(
        { type: "line" },
        {
          type: "text",
          value: `Note : **${payload.payment?.note || ""}`,
          value2: payload.payment?.note || "",
          style: {
            fontFamily: "Arial",
            fontSize: 10,
            fontStyle: "normal",
          },
        },

        {
          type: "newline",
          value: "10",
        }
      );
    }

    //Show Image
    await this.renderImage(content, SETTING_ID.PRINT_PREVIEW_IMAGE);

    content.push(
      {
        replaceId: REPLACE_ID.PREVIEW_FOOTER,
        type: "text",
        value: settingsStore.getValueBysettingId(
          SETTING_ID.PRINTER_PREVIEW_FOOTER,
          null
        ),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },

      {
        type: "text",
        value: "*** Thank you ***",
        style: {
          fontFamily: "Arial",
          fontSize: 8,
          fontStyle: "bold",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },
      {
        type: "text",
        value: "*** Please come back agian ***",
        style: {
          fontFamily: "Arial",
          fontSize: 8,
          fontStyle: "bold",
          align: "center",
        },
      }
    );

    //QR Code
    // await this._renderPrintQrCode(content, payload)

    content = this._checkAndRemoveEmptyLine(content);
    let cmd = {
      // printerName : "Cashier01",
      printerPreset: "0",
      jobId: new Date().valueOf().toString(),
      timestamp: new Date().toISOString(),
      date: dayjs().format("YYYY-MM-DD"),
      content: content,
    };
    this.print_queues.push(cmd);
    return this;
  }

  async setReceipt(receiptPayload: any, { shopName, isCopy = false }) {
    const settingsStore = useSettingsStore();
    let content = [];

    this.renderReceiptCopy(content, isCopy);

    //Show Image
    await this.renderImage(content, SETTING_ID.RECEIPT_LOGO);

    const showTimeInTimeOutSetting = settingsStore.getValueBysettingId(
      SETTING_ID.PRINTER_SHOW_TIMEIN_TIMEOUT,
      false
    );

    let timeInText = `Time In : ${this.showReceiptTime(
      receiptPayload.metadata?.startDate
    )}`;
    let timeOutText = `Time Out : ${this.showReceiptTime(
      receiptPayload.metadata?.endDate
    )}`;
    if (!showTimeInTimeOutSetting) {
      timeInText = "";
      timeOutText = "";
    }

    //draw detail header
    content.push(
      {
        type: "text",
        value: shopName || ``,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },
      // todo: check if can run multiple lines
      {
        replaceId: REPLACE_ID.RECEIPT_HEADER,
        type: "text",
        value: settingsStore.getValueBysettingId(
          SETTING_ID.PRINTER_RECEIPT_HEADER,
          ""
        ),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "center",
        },
      },

      {
        type: "newline",
        value: "20",
      },

      {
        type: "text",
        value: `Date : ${dayjs(receiptPayload.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        flag: "timeInTimeOut",
        type: "text2cols",
        value: timeInText,
        value2: timeOutText,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        type: "text",
        value: `Receipt ID : ${receiptPayload.receiptId}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        type: "text",
        value: `Cashier : ${receiptPayload.employee.name}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        type: "text",
        value: `Guests : ${receiptPayload.metadata.guests}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      {
        type: "text",
        value:
          `${receiptPayload.table}` +
          `${receiptPayload.bill == "all" ? "" : "-" + receiptPayload.bill}`,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
          align: "center",
        },
      },
      { type: "newline", value: "16" },
      { type: "line" }
    );

    if (!showTimeInTimeOutSetting) {
      content = content.filter((c) => {
        if (c.flag == undefined) {
          return c;
        }

        if (c.flag !== "timeInTimeOut") {
          return c;
        }
      });
    }

    const isSkipPriceZero = settingsStore.getValueBysettingId(
      SETTING_ID.PRINTER_JOB_SKIP_PRICE_ZERO,
      false
    );

    // // draw items
    receiptPayload.items.forEach((item) => {
      //Skip Price Zero
      if (
        isSkipPriceZero === true &&
        item.price == 0 &&
        item.addOnPrice === 0
      ) {
        //add on price is topping total amount
        return;
      }

      content.push({
        type: "text2cols",
        value: `${item.qty}  ${item.name}`,
        value2: `${displayCommaMoney(item.extendedPrice, true)}`,
        style: {
          fontFamily: "Arial",
          fontSize: this.customFontSize(10),
          fontStyle: "normal",
        },
      });

      if (item.options2 && item.options2.length > 0) {
        item.options2.forEach((option) => {
          content.push({
            type: "text2cols",
            value: `     - ${option.name}  x${option.qty}`,
            value2: ` `,
            style: {
              fontFamily: "Arial",
              fontSize: this.customFontSize(10),
              fontStyle: "normal",
            },
          });
        });
      }

      const isShowComment = settingsStore.getValueBysettingId(
        SETTING_ID.PRINTER_RECEIPT_COMMENTS,
        false
      );
      if (item.comments && isShowComment) {
        content.push({
          type: "text2cols",
          value: `     # ${item.comments}`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }

      if (item.dc1[2] != 0 && item.dc1[0] == 0) {
        content.push({
          type: "text2cols",
          value: `     - ส่วนลด ${item.dc1[1]}%`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }

      if (item.dc1[2] != 0 && item.dc1[0] == 1) {
        content.push({
          type: "text2cols",
          value: `     - ส่วนลด ${item.dc1[1]}`,
          value2: ` `,
          style: {
            fontFamily: "Arial",
            fontSize: this.customFontSize(10),
            fontStyle: "normal",
          },
        });
      }
    });

    // // draw total summary
    this._drawSummary(content, receiptPayload);

    let received = receiptPayload.payment.received;
    //parse float
    received = parseFloat(received);

    //change
    let change = receiptPayload.payment.change;
    //parse float
    change = parseFloat(change);
    //abs
    change = Math.abs(change);

    //Receive Payment
    this.renderReceivePayment(content, receiptPayload);
    content.push(
      { type: "line" },
      {
        type: "text2cols",
        value: `Change`,
        value2: displayCommaMoney(change, true),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },

      {
        type: "newline",
        value: "20",
      }
    );

    debugger
    if (receiptPayload.payment?.note) {
      content.push({
        type: "text",
        value: `Note : **${receiptPayload.payment?.note || ""}`,
        value2: receiptPayload.payment?.note || "",

        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      });
    }

    content.push({
      type: "newline",
      value: "20",
    });

    //Show Image
    await this.renderImage(content, SETTING_ID.RECEIPT_IMAGE);

    // draw footer thanks
    content.push(
      {
        type: "newline",
        value: "10",
      },
      {
        replaceId: REPLACE_ID.RECEIPT_FOOTER,
        type: "text",
        value: settingsStore.getValueBysettingId(
          SETTING_ID.PRINTER_RECEIPT_FOOTER,
          null
        ),
        style: {
          fontFamily: "Arial",
          fontSize: 10,

          align: "center",
        },
      },
      {
        type: "newline",
        value: "10",
      },
      {
        type: "text",
        value: "*** Thank You ***",
        style: {
          fontFamily: "Arial",
          fontSize: 7,
          fontStyle: "bold",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "20",
      }
    );

    //QR Code
    //await this._renderPrintQrCode(content, receiptPayload)

    this.renderReceiptCopy(content, isCopy);
    content = this._checkAndRemoveEmptyLine(content);

    let cmd = {
      // printerName : 'Cashier01',
      printerPreset: "0",
      jobId: new Date().valueOf().toString(),
      timestamp: new Date().toISOString(),
      date: dayjs().format("YYYY-MM-DD"),
      content: content,
    };
    this.print_queues.push(cmd);
    return this;
  }

  voidBill(receipt: any) {
    let content = [];
    //draw detail header
    content.push(
      {
        type: "text",
        value: `VOIDED BILL (ยกเลิกบิล)`,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
          align: "center",
        },
      },
      // {
      //     type: 'text',
      //     value: `Your addres sample Header`,
      //     style: {
      //         fontFamily: 'Arial',
      //         fontSize: 10,
      //         fontStyle: 'normal',
      //         align: 'center'
      //     }
      // },
      {
        type: "newline",
        value: "24",
      },
      {
        type: "text",
        value: `Table ${receipt.table}`,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
          align: "center",
        },
      },
      {
        type: "newline",
        value: "16",
      },
      {
        type: "text",
        value: `Receipt ID : ${receipt.receiptId}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        type: "text",
        value: `Receipt Date : ${dayjs(receipt.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      {
        type: "text",
        value: `Amount : ${receipt.payment.grandTotal.toFixed(2)}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      {
        type: "text",
        value: `CASHIER : ${receipt.employee.name}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      { type: "newline", value: "5" },
      { type: "line" },

      {
        type: "text",
        value: `VOID AT : ${dayjs().format("DD/MM/YYYY HH:mm:ss")}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },

      {
        type: "text",
        value: `VOID REASON : ${receipt.voided.reason}`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      },
      { type: "newline", value: "16" },

      {
        type: "text",
        value: `SIGN  : ________________________`,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
          align: "left",
        },
      }
    );

    let cmd = {
      // printerName : 'Cashier01',
      printerPreset: "0",
      jobId: new Date().valueOf().toString(),
      timestamp: new Date().toISOString(),
      date: dayjs().format("YYYY-MM-DD"),
      content: content,
    };
    this.print_queues.push(cmd);
    return this;
  }

  _drawSummary(content, receiptPayload) {
    const settingsStore = useSettingsStore();

    const table = receiptPayload.table;
    const posStore = usePosStore();
    const _cardCharge = posStore.tableState[table]?.cc || [0, 0];

    let list = [];
    let summaryList = [];

    summaryList.push(
      { type: "newline", value: "5" },
      { type: "line" },
      {
        type: "text2cols",
        value: `SubTotal`,
        value2: displayCommaMoney(receiptPayload.payment.subTotal, true),
        amount: receiptPayload.payment.subTotal,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },
      {
        type: "text2cols",
        value: `Discount Items`,
        value2: displayCommaMoney(receiptPayload.payment.dc1, true),
        amount: receiptPayload.payment.dc1,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },
      {
        type: "text2cols",
        value: `Service Charge  (${settingsStore.getValueBysettingId(
          SETTING_ID.SERVICE_CHARGE,
          0
        )}%)`,
        value2: displayCommaMoney(receiptPayload.payment.sc, true),
        amount: receiptPayload.payment.sc,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },
      {
        type: "text2cols",
        value: `Addtional Discount`,
        value2: displayCommaMoney(receiptPayload.payment.dc2, true),
        amount: receiptPayload.payment.dc2,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },

      {
        type: "text2cols",
        value: `Card Charge  ${
          _cardCharge[0] === 0 ? _cardCharge[1] + "%" : ""
        }`,
        // value2:  receiptPayload.payment.tax[2].toFixed(2),
        value2: displayCommaMoney(receiptPayload.payment.cc, true),
        amount: receiptPayload.payment.cc,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },
      {
        type: "text2cols",
        value: `VAT`,
        // value2:  receiptPayload.payment.tax[2].toFixed(2),
        value2: displayCommaMoney(receiptPayload.payment.tax, true),
        amount: receiptPayload.payment.tax,
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },

      {
        type: "text2cols",
        value: `Grand Total`,
        value2: `*** ${displayCommaMoney(
          receiptPayload.payment.grandTotal,
          true
        )}`,
        style: {
          fontFamily: "Arial",
          fontSize: 14,
          fontStyle: "bold",
        },
      },
      { type: "doubleline" }
    );

    // summaryList = _.filter(summaryList, s => s.value2 != '0.00')
    summaryList = _.filter(summaryList, (s) => s.amount !== 0);

    // loop summaryList to push
    _.forEach(summaryList, (s) => {
      content.push(s);
    });

    let beforeVat = null;
    if (receiptPayload.payment.tax < 0) {
      beforeVat = (
        receiptPayload.payment.grandTotal -
        Math.abs(receiptPayload.payment.tax) -
        receiptPayload.payment.rounding
      ).toFixed(2);
    } else {
      beforeVat = (
        receiptPayload.payment.grandTotal -
        receiptPayload.payment.tax -
        receiptPayload.payment.rounding
      ).toFixed(2);
    }
    content.push(
      {
        type: "text2cols",
        value: `Before VAT`,
        value2: displayCommaMoney(beforeVat, true), //receiptPayload.payment.beforeTax.toFixed(2),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      },
      {
        type: "text2cols",
        value: `VAT`,
        value2: displayCommaMoney(Math.abs(receiptPayload.payment.tax), true),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      }
    );

    let roundingType = settingsStore.getRoundingType;
    if (
      roundingType != SETTING_ID.ROUNDING_OPTION_NONE &&
      receiptPayload.payment.rounding != 0
    ) {
      content.push({
        type: "text2cols",
        value: `Rounding`,
        value2: displayCommaMoney(receiptPayload.payment.rounding, true),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      });
    }
  }

  openDrawer() {
    let paylaod = {
      printerPreset: "0",
    };
    axios
      .post(this.apiUrl + this.path + "/opendrawer", paylaod)
      .then((response: AxiosResponse) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderReceivePayment(content, receiptPayload) {
    //Title Receive Payments
    content.push(
      { type: "line" },
      {
        type: "text2cols",
        value: `Received`,
        value2: displayCommaMoney(receiptPayload.payment.received, true),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      }
    );

    //Payment Items
    _.forEach(receiptPayload.payment.list, (payment) => {
      content.push({
        type: "text2cols",
        value: `  ${payment.name}`,
        value2: displayCommaMoney(payment.amount, true),
        style: {
          fontFamily: "Arial",
          fontSize: 10,
          fontStyle: "normal",
        },
      });
    });
  }

  renderReceiptCopy(content, isCopy = false) {
    if (isCopy) {
      content.push(
        {
          type: "text",
          value: `*** Copy ***`,
          style: {
            fontFamily: "Arial",
            fontSize: 10,
            fontStyle: "bold",
            align: "center",
          },
        },
        {
          type: "newline",
          value: "14",
        }
      );
    }
  }

  async _renderPrintQrCode(content, receipt) {
    const settingsStore = useSettingsStore();
    const qrSetting = settingsStore.getValueBysettingId(
      SETTING_ID.QR_PROMTPAY,
      false
    );

    const usePrompay = await promptpay(
      "promtpay",
      qrSetting.id,
      qrSetting.name,
      receipt.payment.grandTotal
    );
    const qrBase64 = usePrompay.split(",");

    if (qrSetting.active) {
      content.push({
        type: "image",
        value: `${qrBase64 && qrBase64[1]}`,
        style: {
          scale: 0.8,
        },
      });
    }
  }

  _checkAndRemoveEmptyLine(content) {
    return _.filter(content, (c) => {
      if (c.replaceId && !c.value) {
        return false;
      }
      return true;
    });
  }

  showReceiptTime(date) {
    return dayjs(date).format("HH:mm");
  }

  customFontSize(current: number) {
    const settingsStore = useSettingsStore();
    const customFontSize = settingsStore.getValueBysettingId(
      SETTING_ID.PRINTER_RECEIPT_FONT_SIZE,
      0
    );
    return current + Math.ceil(current * customFontSize);
  }

  async renderImage(content, settingId, scale = 0.5, newLine = "150") {
    const settingsStore = useSettingsStore();
    const imageSetting = settingsStore.getValueBysettingId(settingId, "");
    if (imageSetting && imageSetting.url) {
      const imageBase64 = await convertImgUrlToBase64(imageSetting.url);
      const splited = imageBase64.split(",");
      content.push(
        {
          type: "image",
          value: (splited && splited[1]) || ``,
          style: {
            scale: scale,
          },
        },
        {
          type: "newline",
          value: newLine,
        }
      );
    }
  }
}
