import { JobCard } from "~~/interface/Job";
import _ from "lodash";
import { JobAction, JobCard, JobItemStatus } from "~~/interface/Job";
import { JobItem } from "~~/interface/Job";

export default class JobschainV2 {
  jobs: Array<JobCard> = [];
  itemsUuid: { [uuid: string]: JobItem | null } = {};
  voidUuid = {};
  moveUuid = {};
  _tables = {};
  uuidDeadList = [];
  firstDeadListLoaded = false;

  uuidMapQty: any = {};

  constructor(jobs) {
    this.jobs = jobs;
    this.compute();
  }

  compute(forceJobs = null) {
    // console.log(_)
    let sorted = _.sortBy(forceJobs || this.jobs, (j) => j.timestamp);
    // sorted = _.shuffle(sorted);
    _.forEach(sorted, (job: JobCard, index) => {
      try {
        switch (job.action) {
          case JobAction.SET_START_TIME:
            this.setStartTime(job);
            break;
          case JobAction.SET_GUESTS:
            this.setGuests(job);
            break;

          //items
          case JobAction.ADD:
            this.add(job);
            break;
          case JobAction.MOVE:
            this.move(job);
            break;
          case JobAction.SPLIT_ITEM:
            this.splitItem(job);
            break;
          case JobAction.SPLIT_BILL:
            this.splitBill(job);
            break;
          case JobAction.SET_PRICE:
            this.setPrice(job);
            break;
          case JobAction.SET_DISCOUNT:
            this.setDiscount(job);
            break;
          case JobAction.SET_DISCOUNT_ENT:
            this.setDiscount(job);
            break;
          case JobAction.VOID:
            this.void(job);
            break;
          case JobAction.VOID_RECEIPT:
            this.voidReceipt(job);
          case JobAction.PAY:
            this.pay(job);
            break;
        }
      } catch (error) {
        console.error(error);
        console.error(job);
        console.error("index", index);

        throw error;
      }
    });

   
  }

  getItems() {
    return this.itemsUuid;
  }

  getTables() {
    //group items by table
    let tables = {};
    let tableJobId = {};
    let items = this.itemsUuid;
    _.forEach(items, (item) => {
      if (item.status == JobItemStatus.ACTIVE) {
        let table = item.table || "_unknown_";
        if (!tables[table]) {
          tables[table] = {
            items: [],
            jobs: [], // jobid
            ...this._tables[table],
          };
        }
        tables[table].items.push(item);

        let k = `${table}-${item.jobId}`;
        if (tableJobId[k] == undefined) {
          tableJobId[k] = true;
          tables[table].jobs.push(item.jobId);
        }
      }
    });

    return tables;
  }

  updateItemLine(newItemUpdate: JobItem, status: JobItemStatus, options = {}) {
    let item_prevoius = this.itemsUuid[newItemUpdate.uuid];
    if (
      item_prevoius?.status == JobItemStatus.PAID ||
      item_prevoius?.status == JobItemStatus.VOIDED
    ) {
      return;
    }
    this.itemsUuid[newItemUpdate.uuid] = {
      ...item_prevoius,
      ...newItemUpdate,
      ...options,
      status: status,
    };
  }

  add(job: JobCard) {
    _.forEach(job.items, (itemLineFromJob: ItemDatabase, i) => {

      // สำหรับ ADD ต้องทำการเทค่า ทีหลัง
      let newItem = {
        ...itemLineFromJob,
        table: job.table,
        jobId: job.jobId,
        bill: 1,
        row: i * 10,
        timestamp: job.timestamp,
        employeeId: job.employee.employeeId,
        employeeName: job.employee.name,
        discountItem: [0, 0, 0],
      };

      let item_prevoius = this.itemsUuid[itemLineFromJob.uuid];
      this.itemsUuid[itemLineFromJob.uuid] = {
        ...newItem,
        ...item_prevoius,
        status: item_prevoius?.status || JobItemStatus.ACTIVE,
      };
    });

    if (this._tables[job.table] == undefined) {
      this._tables[job.table] = {
        startDate: job.timestamp,
        guests: job.guests,
        zone: job.zone || null,
      };
    }

    if (job.guests >= 1 && job.channel !== "qrcode") {
      this._tables[job.table] = {
        startDate: job.timestamp,
        guests: job.guests,
        zone: job.zone || null,
      };
    }

    // if (job.guests >= 1 ) {
    //     this._tables[job.table] = {
    //         startDate: job.timestamp,
    //         guests: job.guests
    //     }
    // }

    // this._tables[job.table] = {...this._tables[job.table]}
  }

  move(job: JobCard) {
    let oldTable = job.table[0];
    let newTable = job.table[1];

    // check ว่ามีคนใช้งานอยู่มั้ย หรือ เปิดโต๊ะใหม่
    //check the table is already taken or not, by find one item in new table if found, then the table is taken
    let isTableTaken = _.find(this.itemsUuid, (item) => {
      return item.table == newTable && item.status == JobItemStatus.ACTIVE;
    });

    // if new table is empty, set start time and guests from old table
    if (!isTableTaken) {
      // if( this._tables[newTable]  == undefined) {

      //     this._tables[newTable] = {
      //         startDate: job.timestamp,
      //         guests:2
      //     }
      // }

      this._tables[newTable] = {
        startDate: job.timestamp, // this._tables[oldTable].startDate,
        guests: 2, //this._tables[oldTable].guests
      };
    }

    _.forEach(job.items, (itemLineFromJob) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.ACTIVE, {
        table: newTable,
        jobId: job.jobId,
        timestamp: job.timestamp,
      });
    });
  }

  splitItem(job: JobCard) {
    _.forEach(job.items, (itemLineFromJob) => {
      let thisItem = this.itemsUuid[itemLineFromJob.uuid];

      // new clone item
      let newItem = _.cloneDeep(thisItem);
      newItem.uuid = itemLineFromJob.newUuid;
      newItem.newLine = true;
      newItem.splited = true;

      newItem.qty = Math.abs(itemLineFromJob.qty);
      newItem.row = thisItem.row + 1;

      this.updateItemLine(newItem, JobItemStatus.ACTIVE);

      // update original item
      thisItem.qty = thisItem.qty - Math.abs(newItem.qty);
      this.updateItemLine(thisItem, JobItemStatus.ACTIVE);

      // if qty <= 0 then void the item
      if (thisItem.qty <= 0) {
        this.updateItemLine(itemLineFromJob, JobItemStatus.VOIDED);
      }
    });
  }
  splitBill(job: JobCard) {
    _.forEach(job.items, (itemLineFromJob) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.ACTIVE);
    });
  }

  // void items
  void(job: JobCard) {
    _.forEach(job.items, (itemLineFromJob: ItemDatabase) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.VOIDED, {
        reason: job.note || "-",
      });
    });
  }

  voidReceipt(job: JobCard) {
    // find one that match hash
    let payment_job = _.find(this.jobs, (j) => {
      return j.hash == job.hash;
    });

    //if found, void all items
    if (payment_job) {
      _.forEach(payment_job.items, (itemLineFromJob: ItemDatabase) => {
        this.updateItemLine(itemLineFromJob, JobItemStatus.VOIDED);
      });
    }
  }

  setPrice(job) {
    _.forEach(job.items, (itemLineFromJob) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.ACTIVE);
      let thisItem = this.itemsUuid[itemLineFromJob.uuid];
      if (thisItem?.options2) {
        thisItem.options2.forEach((option) => {
          option.price = 0;
        });
      }
    });
  }
  setDiscount(job) {
    _.forEach(job.items, (itemLineFromJob) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.ACTIVE);
    });
  }

  pay(job: JobCard) {
    _.forEach(job.items, (itemLineFromJob: ItemDatabase) => {
      this.updateItemLine(itemLineFromJob, JobItemStatus.PAID);
    });
  }

  //status table methods
  setGuests(job: JobCard) {
    let table = job.table;

    // if table is not in the list, add it
    if (!this._tables[table]) {
      return;
    }

    // add guests to the table
    this._tables[table].guests = job.guests || 1;
  }

  setStartTime(job: JobCard) {
    let table = job.table;
    this._tables[table].startDate = job.value;
  }
}
