
export interface JobCard {
    jobId : string,
    timestamp : string | number,
    table: string  // "A1", "B2", "C3", ...
    newTable?  : string,   // use for move table
    type : 'job_card',  // wallet | job_card
    orderType: 'dine_in' | 'take_away' | 'delivery' | 'online' | 'pre_order',    
    action: JobAction,
    items: Array<JobItem>  //[JobA] //array <JobA>
     comments? : string
    guests? : number
    value? : number  // มูลค่า subitem, deposit, discount, payment
    print? : true
  
    paid? : true
    employee?: {
        id: string,
        name: string
    }
    // ลูกค้าสั่งเองจากมือถือ
    user?: {
        id: string,
        name: string
    }
    deviceId?: string
    sync?: string | null // time of sync
    nonce? : any
}

export enum JobAction {
    OPEN = "OPEN",

    //status table
    QR_CODE_ORDER = "QR_CODE_ORDER",
    LOCK_TABLE = "LOCK_TABLE",
    PRINT_PREVIEW = "PRINT_PREVIEW",
    SET_START_TIME = "SET_START_TIME",
    SET_GUESTS = "SET_GUESTS",

    // items
    ADD = "ADD",
    MOVE = "MOVE",
    SPLIT_ITEM = "SPLIT_ITEM",
    SPLIT_BILL = "SPLIT_BILL",
    SET_PRICE = 'SET_PRICE',
    SET_DISCOUNT = 'SET_DISCOUNT',
    SET_DISCOUNT_ENT  = 'SET_DISCOUNT_ENT',
    OPEN_MENU = 'OPEN_MENU',
    VOID = "VOID",  //void_items
    PAY = "PAY",
    REPRINT = "REPRINT",

    // receipt
    VOID_RECEIPT = "VOID_RECEIPT",

    //shop
    CLOSE = "CLOSE",
}


export enum JobItemStatus {
    ACTIVE=  'active',
    VOIDED  = 'voided',
    MOVED = 'moved',
    PAID = 'paid'
}

export interface JobItem {
    uuid: string
    productId?: string
    qty?: number
    price?: number
     comments?: string
    options?: Array<JobItem>
    discount?: [mode: number, value: number]//
    table? : string | Array<string>
    status?: 'void' | 'paid' | 'moved' | 'active'

    jobId : string
    timestamp : string
    // computed

}

export enum ErrorMessage {
    DISCOUNT_INVALID = 'การให้ส่วนลดต้องไม่เกิน 100%',
    DISCOUNT_VALIDATE_INPUT = 'กรุณากรอกส่วนลด',
    VAT_VALIDATE_INPUT = 'กรุณากรอก TAX',
    VALIDATE_PRICE = 'กรุณากรอกราคา'
}

