import { nanoid } from "nanoid";
import { JobAction, JobItemStatus } from "~/interface/Job";
import { RunMode } from "~~/interface/Pos";
import { child, onChildAdded, onValue, remove } from "firebase/database";
import _ from "lodash";
import { useServerTime } from "~/modules/core/utils/timestamp";
import dayjs from "dayjs";

export const usePosStore = defineStore("pos", {
  state: () => {
    return {
      runmode: RunMode.UNASSIGNED,
      loading: false,
      //------zone tables-------------------
      zoneList: [
        //     {
        //     id: 1,
        //     name: "T",
        //   //  name: "นอกร้าน",
        //     value: 50
        //     },
        //     {
        //         id: 1,
        //         name: "A",
        //         value: 50
        //     },
      ],
      tableList: [] as any,
      activeList: [] as any,
      selectedZone: null,
      selectedTable: null,

      uuidHolder: {}, // for click items
      tableState: {
        A1: { lock: false },
      },

      //------modal_items_editor-------------------
      editModeActive: false, //modal
      editMode: null as JobAction, //modal
      editModeTitle: "",
      billFilter: "all",
      grandTotalFilter: 0,
      inputValue: {
        reason: "", //void
        price: 0,
        discountMode: 0,
        discountValue: 0,
        bill: 1,
        newTable: "",
        splitQty: 1,
      },
      nonce: nanoid(),
      isShowUnderstandCrossDate: false,
      isBeta: false,
    };
  },

  actions: {
    init() {
      this.watchTableState();
    },
    watchTableState() {
      let fbInstance = useFirebase();
      let tableStateRef = fbInstance.tableStateRef();

      onValue(tableStateRef, (snapshot) => {
        const data = snapshot.val();
        this.tableState = data || {};
      });
    },
    checkRunMode() {
      // rund mode
      let mode = useRunningMode().getMode() || RunMode.UNASSIGNED;
      this.runmode = mode;
    },
    setRunMode(mode) {
      this.runmode = mode;
      useRunningMode().setMode(mode);
    },
    selectZone(zone, type = "zone") {
      this.selectedZone = zone;

      let list = [];
      _.times(zone.value, (i) => {
        list.push(zone.name + "" + (i + 1));
      });
      this.tableList = list;
    },
    selectAllZone(tableSetting) {
      let list = [];

      for (const eachSetting of tableSetting) {
        _.times(eachSetting.value, (i) => {
          list.push(eachSetting.name + "" + (i + 1));
        });
      }

      this.tableList = list;
    },
    selectActiveTables(table) {
      const jobsStore = useJobsStore();
      let tables = _.keys(jobsStore.tables_v2);

      tables = sortTables(tables);
      const groupedData = tables.reduce((acc, item) => {
        const match = item.match(/^([A-Za-z\s]+)(\d+)$/);
        if (match) {
          const letter = match[1].trim();

          if (!acc[letter]) {
            acc[letter] = [];
          }
          acc[letter].push(item);
        }
        return acc;
      }, {});

      this.activeList = groupedData;
    },
    setUnderstandCrossDate() {
      this.isShowUnderstandCrossDate = true;
    },

    getTableListBySetting(tableKeys, tableSetting) {
      let groupedTables = {};

      console.log("tableKeys=>", tableKeys, tableSetting);

      for (const tableKey of tableKeys) {
        if (!groupedTables[tableKey]) {
          groupedTables[tableKey] = [];
        }
        groupedTables[tableKey].push(tableKey);

        //let zoneId = tableKey.match(/^([A-Za-z\s]+)(\d+)$/);

        // const settingDataWithJob = tableSetting.concat({"name": "JOB"});
        // let setting = settingDataWithJob.find((s) => s.name === zoneId[1]);
        //   if (setting) {
        //       // If setting exists, check if the zoneId is already in groupedTables
        //       if (!groupedTables[setting.name]) {
        //           // If not, create a new entry with an array containing the table data
        //           groupedTables[setting.name] = [];
        //       }
        //       // Push the table data into the corresponding zoneId group
        //       groupedTables[setting.name].push(tableKey);
        //   } else {
        //       // Handle cases where the setting for the zoneId is not found
        //       console.log(`Setting not found for zoneId: ${zoneId}`);
        //   }
      }

      console.log("groupedTables=>", groupedTables);
      this.activeList = groupedTables;
    },

    selectTable(table) {
      this.selectedTable = table;
      this.uuidHolder = {};
    },

    //---
    move(targetTable) {
      const jobsStore = useJobsStore();

      let items = this.uuidHolder;
      items = _.values(items);
      // lodash remove falsty value
      items = _.compact(items);

      const itemsArray = _.map(items, (v, k) => {
        return {
          uuid: v.uuid,
          productId: v.productId,
          qty: v.qty,
          name: v.name,
        };
      });

      let newTable = this.inputValue.newTable;

      // for summary
      let job = new JobBuilder()
        .withTable([this.selectedTable, newTable])
        .withItems(itemsArray)
        .withAction(JobAction.MOVE)
        .withEmployee(this.getEmployee())

        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    lockTable(table) {
      let myTable = this.tableState[table];

      this.tableState = {
        ...this.tableState,
        [table]: { ...myTable, lock: true },
      };

      return this.tableState;
    },
    discount() {
      let mode = this.inputValue.discountMode;
      let value = this.inputValue.discountValue;
      const jobsStore = useJobsStore();

      let items = this.uuidHolder;
      items = _.values(items);
      items = _.compact(items);
      items = _.map(items, (v, k) => {
        return {
          uuid: v.uuid,
          productId: v.productId,
          qty: v.qty,
          discountItem: [mode, value],
        };
      });

      // for summary
      let job = new JobBuilder()
        .withTable(this.selectedTable)
        .withItems(items)
        .withEmployee(this.getEmployee())

        .withAction(JobAction.SET_DISCOUNT)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )
        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    void() {
      let reason = this.inputValue.reason;

      const jobsStore = useJobsStore();

      let items = this.uuidHolder;
      items = _.values(items);
      items = _.compact(items);
      items = _.map(items, (v, k) => {
        return {
          uuid: v.uuid,
          productId: v.productId,
          qty: -v.qty,
          ...v,
        };
      });

      // for summary
      let job = new JobBuilder()
        .withTable(this.selectedTable)
        .withNote(reason + " (by " + this.getEmployee().name + ")")
        .withItems(items)
        .withEmployee(this.getEmployee())
        .withAction(JobAction.VOID)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )
        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    printPreview(table) {
      let myTable = this.tableState[table];

      //lock
      this.tableState = {
        ...this.tableState,
        [table]: { ...myTable, lock: true },
      };

      //print preview
      const jobsStore = useJobsStore();
      const posStore = usePosStore();
      const shopStore = useShopStore();
      const employeePinStore = useEmployeePinStore();
      const settingsStore = useSettingsStore();

      const roundingType = settingsStore.getRoundingType;

      let bill = posStore.billFilter;
      // build Receipt
      let itemsCombined = jobsStore.itemsCombined(posStore.selectedTable, {
        bill,
      });

      // let moneySummary = jobsStore.computeMoneyOneTable(posStore.selectedTable, roundingType)
      let moneySummary = jobsStore.computeMoneyOneTableWithItemsFiltered(
        {
          items: itemsCombined,
        },
        roundingType
      );

      let _payment = {
        paymentList: [],
        received: 0,
        change: 0,
        summary: moneySummary,
        note: myTable?.note || "",
      };

      let guest =
        this.tableState[table].guests || jobsStore.tables[table].guests || 2;

      let preview = new PreviewBuilder()
        .withTable(table)
        .withItems(itemsCombined)
        .withPayment(_payment)
        .withCreatedAt(
          useServerTime()
            .getCorrectedDayjsInstance()
            .format("YYYY-MM-DD HH:mm:ss")
        )
        .withEmployee(employeePinStore.employee)
        .withStoreId(shopStore.storeId)
        .withGuests(guest)
        .withBill(bill == "all" ? undefined : bill)
        .build();

      setTimeout(async () => {
        const printPreview = await new ThermalPrinterReceipt().setPrintPreview(
          preview
        );
        printPreview.print();
      }, 20);

      return this.tableState;
    },
    printPreviewFromMobile(table, employee) {
      let myTable = this.tableState[table];

      //lock
      this.tableState = {
        ...this.tableState,
        [table]: { ...myTable, lock: true },
      };

      //print preview
      const jobsStore = useJobsStore();
      const posStore = usePosStore();
      const shopStore = useShopStore();
      const employeePinStore = useEmployeePinStore();
      const settingsStore = useSettingsStore();

      const roundingType = settingsStore.getRoundingType;

      let bill = posStore.billFilter;
      // build Receipt
      let itemsCombined = jobsStore.itemsCombined(table, { bill });

      let moneySummary = jobsStore.computeMoneyOneTable(table, roundingType);

      let _payment = {
        paymentList: [],
        received: 0,
        change: 0,
        summary: moneySummary,
        note: myTable?.note || "",
      };

      let guest =
        this.tableState[table].guests || jobsStore.tables[table].guests || 2;

      let preview = new PreviewBuilder()
        .withTable(table)
        .withItems(itemsCombined)
        .withPayment(_payment)
        .withCreatedAt(
          useServerTime()
            .getCorrectedDayjsInstance()
            .format("YYYY-MM-DD HH:mm:ss")
        )
        .withEmployee(employee)
        .withStoreId(shopStore.storeId)
        .withGuests(guest)
        .build();

      setTimeout(async () => {
        const printPreview = await new ThermalPrinterReceipt().setPrintPreview(
          preview
        );
        printPreview.print();
      }, 20);

      return this.tableState;
    },
    unlockTable(table) {
      let tableState = { ...this.tableState };
      tableState = {
        ...tableState,
        [table]: { ...tableState[table], lock: false },
      };

      this.tableState = tableState;
      return tableState;
    },
    selectBill(billSelected) {
      this.billFilter = billSelected;
    },
    splitItem(clonedItem) {
      // let newQty = this.inputValue.splitQty
      // newQty = 1

      // let items = this.uuidHolder
      // items = _.values(items)
      // let item = items[0]; // only one item
      // if (items.length >=2 ) {
      //     alert("เลือกสินค้า แยก ได้เพียง 1 รายการเท่านั้น")
      //         return
      // }
      const jobsStore = useJobsStore();

      let newItems = [
        {
          uuid: clonedItem.uuid,
          newUuid: nanoid(4),
          productId: clonedItem.productId,
          qty: -clonedItem.qty,
          table: clonedItem.table,
          value: [clonedItem.originalQty, clonedItem.qty],
        },
      ];

      // for summary
      let job = new JobBuilder()
        .withTable(this.selectedTable)
        .withItems(newItems)
        .withEmployee(this.getEmployee())
        .withAction(JobAction.SPLIT_ITEM)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    splitBill() {
      let billNo = this.inputValue.bill;

      const jobsStore = useJobsStore();

      let items = this.uuidHolder;
      items = _.values(items);
      items = _.compact(items);
      items = _.map(items, (v, k) => {
        return {
          uuid: v.uuid,
          productId: v.productId,
          bill: billNo,
        };
      });

      // for summary
      let job = new JobBuilder()
        .withTable(this.selectedTable)
        .withItems(items)
        .withEmployee(this.getEmployee())
        .withAction(JobAction.SPLIT_BILL)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    setPrice() {
      let newPrice = this.inputValue.price;

      const jobsStore = useJobsStore();

      let items = this.uuidHolder;
      items = _.values(items);
      items = _.compact(items);
      items = _.map(items, (v, k) => {
        return {
          uuid: v.uuid,
          productId: v.productId,
          price: newPrice,
          name: v.name,
          beforePrice: v.extendedPrice,
        };
      });

      // for summary
      let job = new JobBuilder()
        .withTable(this.selectedTable)
        .withItems(items)
        .withEmployee(this.getEmployee())
        .withAction(JobAction.SET_PRICE)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    setGuestQty(table, qty) {
      const jobsStore = useJobsStore();

      // for summary
      let job = new JobBuilder()
        .withTable(table)
        .withGuests(qty)
        .withEmployee(this.getEmployee())
        .withAction(JobAction.SET_GUESTS)
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )
        .build();

      this.setGuest(table, qty);
      jobsStore.add(job, true);
      this.uuidHolder = {};
    },
    openShop() {
      const jobsStore = useJobsStore();

      // for summary
      let job = new JobBuilder()
        .withAction(JobAction.OPEN)
        .withTable(null)
        .withEmployee(this.getEmployee())
        .withCustom({
          nonce: _.random(1, 1000000),
          businessDate: useServerTime()
            .getCorrectedDayjsInstance()
            .format("YYYY-MM-DD"),
          shift: nanoid(3),
        })
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);
    },
    closeShop() {
      const jobsStore = useJobsStore();

      // for summary
      let job = new JobBuilder()
        .withAction(JobAction.CLOSE)
        .withTable(null)
        .withEmployee(this.getEmployee())
        .withCustom({
          nonce: _.random(1, 1000000),
          businessDate: useServerTime()
            .getCorrectedDayjsInstance()
            .format("YYYY-MM-DD"),
          shitf: jobsStore?.openShop?.shift || "001",
        })
        .withJobIdAndTimestamp(
          useServerTime().getCorrectedDayjsInstance().toISOString()
        )

        .build();

      jobsStore.add(job, true);

      // setTimeout(() => {
      //     this._doCloseShop()
      // }, 1000);
    },
    setGuest(table, qty) {
      let tableState = { ...this.tableState };
      tableState = {
        ...tableState,
        [table]: { ...tableState[table], guests: qty },
      };

      this.tableState = tableState;
      return tableState;
    },
    clearGuest(table) {
      let tableState = { ...this.tableState };
      delete tableState[table].guests;
      tableState = {
        ...tableState,
        [table]: { ...tableState[table] },
      };

      this.tableState = tableState;
      return tableState;
    },
    setNote(table, note) {
      let tableState = { ...this.tableState };
      tableState = {
        ...tableState,
        [table]: { ...tableState[table], note },
      };

      this.tableState = tableState;
      return tableState;
    },
    clearNote(table) {
      let tableState = { ...this.tableState };
      delete tableState[table].note;
      tableState = {
        ...tableState,
        [table]: { ...tableState[table] },
      };

      this.tableState = tableState;
      return tableState;
    },
    setChannel(table, channel) {
      let tableState = { ...this.tableState };
      tableState = {
        ...tableState,
        [table]: { ...tableState[table], channel },
      };

      this.tableState = tableState;
      return tableState;
    },

    clearChannel(table) {
      let tableState = { ...this.tableState };
      delete tableState[table].channel;
      tableState = {
        ...tableState,
        [table]: { ...tableState[table] },
      };

      this.tableState = tableState;
      return tableState;
    },
    setServiceChange(table, sc) {
      let tableState = { ...this.tableState };
      tableState = {
        ...tableState,
        [table]: { ...tableState[table], sc },
      };

      this.tableState = tableState;
      return tableState;
    },
    clearServiceChange(table) {
      let tableState = { ...this.tableState };
      delete tableState[table].sc;
      tableState = {
        ...tableState,
        [table]: { ...tableState[table] },
      };

      this.tableState = tableState;
      return tableState;
    },
    getEmployee() {
      const employeePinStore = useEmployeePinStore();
      return {
        employeeId: employeePinStore.employee.employeeId,
        name: employeePinStore.employee.name,
      };
    },
  },
});
