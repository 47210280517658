export const displayCommaMoney = (moneyValue, isShowZero=false) => {
    let value = useDisplayMoney(moneyValue, isShowZero);

    // Ensure the value is a number
    let numericValue = Number(value);

    if (!isNaN(numericValue)) {
        // Convert to fixed decimal places
        let fixedValue = numericValue.toFixed(2);

        // Apply commaNumber
        let formattedValue = fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Remove ".00" if the value ends with it
        if (formattedValue.endsWith(".00") && !isShowZero) {
            formattedValue = formattedValue.slice(0, -3); // Remove the last three characters
        }

        return formattedValue;
    } else {
        console.error("Value is not a number:", value);
        return value;
    }
};
