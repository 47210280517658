import { set } from "firebase/database";
import _ from "lodash";
import dataservice from "../../core/services/dataservice";
import { SETTING_ID } from "~/interface/Setting";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    settings: null as any,
    isLoading: false,
    apiUrl:
      "https://b8f74o2sda.execute-api.ap-southeast-1.amazonaws.com/general/settings",
  }),
  getters: {
    getValueBysettingId(state) {
      return (settingId: string, defaultValue: any) => {
        let data = state.settings?.find((x: any) => x.settingId === settingId);
        if (data) {
          return data.value;
        } else {
          return defaultValue;
        }
      };
    },
    getBysettingId(state) {
      return (settingId: string, defaultValue: any) => {
        let data = state.settings?.find((x: any) => x.settingId === settingId);
        if (data) {
          return data;
        } else {
          return defaultValue;
        }
      };
    },
    getStoreName(state) {
      let data = state.settings?.find((x: any) => x.settingId === "name");
      if (data) {
        return data.value;
      } else {
        return "No Name";
      }
    },
    getRoundingType(state){
      let data = state.settings?.find((x: any) => x.settingId === SETTING_ID.ROUNDING);
      return data?.value || SETTING_ID.ROUNDING_OPTION_NEAREST      
    },
    qrMenuList(state) {
      // return (ts) => {
      if (!state.settings) {
        return [];
      }
      let qrMenuList = state.settings?.filter((x: any) => {
        return x?.settingId?.startsWith("qrcode_menu_");
      });
      if (qrMenuList) {
        return qrMenuList?.filter((x: any) => x.active == true);
      } else {
        return [];
      }
      //  }
    },
    findMenuSetting(state) {
      return (menuId) => {
        return _.find(state.settings, { settingId : menuId });
      }
    },
    getRooms(state) {
      let data = state.settings?.find((x: any) => x.settingId === "roomCharge");
      if (data) {
        return data?.value || [];
      } else {
        return [];
      }
    },
  },
  actions: {
    async main() {
      await this.fetch();
    },
    async fetch() {
      this.isLoading = true;

      let res = await dataservice.settings.getAll();

      this.settings = res.data;

      this.isLoading = false;

      return res.data;
    },
    async insert(data: any) {
      let res = await dataservice.settings.post(data);
      await useDelaySecs(1)
      await this.fetch();
      await this.syncToFirebase();
      return res;
    },
    async update(data: any) {
      // use POST for update to overwrite the whole object
      let res = await dataservice.settings.post(data);
      await useDelaySecs(1)
      
      await this.fetch();
      await this.syncToFirebase();
      return res;
    },
    async updatePartial(data: any) {
        
      let res = await dataservice.settings.update(data);
      await useDelaySecs(1)
      await this.fetch();
      await this.syncToFirebase();
      return res
    },
    async delete(setting: any) {
      // await axios
      //   .delete(this.apiUrl, setting)
      //   .then((response) => response)
      //   .catch((err) => console.error(err));
    },

    syncToFirebase() {
      let settings = this.settings;
      let settingsRef = useFirebase().settingsRef();

      let paylaod = _.keyBy(settings, "settingId");

      set(settingsRef, settings);

      let shopNameRef = useFirebase().shopNameRef();
      let _name = this.getStoreName;
      set(shopNameRef, _name);
    },
  },
});
